<template>
<Transition name="slide-fade" appear>
    <div class="md:w-4/6 m-auto bg-white text-t3 container"> 
        <div class="p-12 rounded shadow-t3 items-center relative md:mt-0">


            <h2 class="text-2xl text-center font-bold sm:text-3xl dark:text-white">
                    Book New Assessments
                </h2>

            <div class="w-4/6 m-auto mt-12 text-center">
                <h1 v-if="user"  class="mt-8 form-control block w-full text-xl font-normal text-t3 bg-white transition ease-in-out m-0 focus:outline-none"><span class="text-gray-400 text-sm">Booking for</span> {{customer.full_name }}</h1>
                <router-link v-if="user"  :to="{name:'BookNew'}" @click="Assess.customer=null" class="mb-8 mt-2 form-control block w-full text-sm font-normal hover:text-t3-teritiary text-t3-cancel bg-white transition ease-in-out focus:outline-none">book for other</router-link>
                <div v-if="!user" class="relative text-gray-600 focus-within:text-gray-400">
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg v-if="!Assess.customer" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6 text-gray-500">
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                        <svg v-if="Assess.customer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-t3-secondary">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                    </span>
                    <input id="search" v-if="!Assess.customer" :class="{ 'searcher': customerOnly.length >0 }" v-model="search" @input="debCheck()" aria-placeholder="Search" placeholder="Search for Customer and Select from List"
                        class="py-2 h-12 pl-10 block w-full border-solid outline-none border border-gray-300 rounded  focus:text-gray-700"
                        type="search" name="search" required autocomplete="search" />
                    <input @click="reSearch"  v-if="Assess.customer" type="text"  v-model="customerName" aria-placeholder="Search" placeholder="Search"
                        class="py-2 h-12 pl-10 block w-full border-solid border outline-none cursor-pointer hover:bg-red-200 hover:text-red-600 border-gray-300 bg-white rounded focus:text-gray-700"
                         />
                    <div id="list" v-if="!Assess.customer" :class="{ 'lister': customerOnly.length === 0 }" class="absolute z-10 m-0 flex flex-col items-center hidden justify-center bg-white rounded rounded-t-none w-full border-gray-300 border-t-0 border">
                    <button v-for="customer in customerRange" class="text-t3 hover:bg-gray-300 hover:text-white w-full"  @click="suggestcustomer(customer.id,customer.full_name, customer)" :key="customer.id">{{customer.full_name}}</button>
                    </div>
                </div>
                <div v-if="selected">
                    <p class="text-t3-mutedText text-sm my-8" v-if="!selected.customerprofile ">It appears this user does not have their profile configured, please do so through <router-link class="text-t3-secondary" :to="{name: 'UserDetails', params: {id: selected.id}}">their profile page</router-link>. </p>
                </div>

                <select v-model="Assess.discount_rate"  class="my-8  h-12 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                
                    <option disabled :value="null">Select Discount Rate (Current: Company Standard)</option>
                <option v-for="discount in optionsList"  :value="discount.id" :key="discount.id">{{discount.name}}  {{discount.discount_rate}}% </option>
                </select>
                <label for="datepick" class="text-sm text-gray-400 mt-8">Highlighted dates have available slots </label>
                <Datepicker id="datepick" @update:modelValue="newSlots" v-model="Assess.date" :enableTimePicker="false"  :min-date="new Date()" ht="highlighted" datePicker placeholder="Select Date"  class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                <div class=" flex w-full items-center">
                    <select @change="slotSelect" v-model="Assess.slot" class="h-12 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                    <option v-if="freeSlot.length === 0" disabled :value="null">No slots available for the selected date</option>
                    <option v-if="freeSlot.length !== 0" disabled :value="null">Select Slot</option>
                    <option v-for="slot in freeSlot"  :value="slot.id" :key="slot.id">{{slot.start_time}}</option>
                    </select>
                    <button type="button"
                    v-if="Assess.date"
                        class=" text-xl h-12 w-12 ml-4 inline-block text-center bg-t3Light text-t3 border-t3 border-solid border hover:border-t3-secondary font-medium leading-tight uppercase rounded hover:bg-t3-secondary hover:text-white focus:bg-t3 focus:shadow-lg focus:text-white focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                        +
                        </button>
                </div>
                <select  v-model="Assess.OPE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option disabled :value="null">Select OPE</option>
                <option v-for="staff in OPE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                </select>
                <select  v-model="Assess.ELE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option disabled :value="null">Select ELE</option>
                <option v-for="staff in ELE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                </select>
                <select  v-model="Assess.location" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                    <option disabled :value="null">Select Location</option>
                    <option v-for="location in addresses"  :value="location.id" :key="location.id">{{location.name}}</option>
                </select>

                <button @click="book()" class=" w-48 m-auto mt-12 mb-5 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Book</button>
                <button @click="bookRef()" class=" w-48 m-auto font-medium border border-t3 p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary" >Book and add another</button>
            </div>

            <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5 v-if="Assess.date" class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
                Add Slot to {{Assess.date.toISOString().substr(0,10)}}
                </h5>
                <button type="button"
                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body relative p-4">

        <!-- Non-native datepicker for browser friendliness -->

                <Datepicker v-model="timeToSend" timePicker placeholder="Select Time" />
                <select  v-model="sendOPE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option :value="null">Select OPE</option>
                <option v-for="staff in OPE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                </select>
                <select  v-model="sendELE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option :value="null">Select ELE</option>
                <option v-for="staff in ELE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                </select>
                <select  v-model="sendlocation" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                    <option disabled :value="null">Select Location</option>
                    <option v-for="location in addresses"  :value="location.id" :key="location.id">{{location.name}}</option>
                </select>

            </div>
            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button type="button"
                @click="timeEnd" id="close" class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                data-bs-dismiss="modal">
                Close
                </button>
                <button type="button"
                @click="timeSend" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                Add Slot
                </button>
            </div>
            </div>
        </div>
        </div>


        </div>
    </div>
</Transition>

</template>

<script>
// import { watchEffect } from 'vue'
import ReportServices from '@/services/ReportServices';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import addDays from 'date-fns/addDays';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { ref, watchEffect } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '../router';
import SettingsServices from '../services/SettingsServices';
import SlotsServices from "../services/SlotsServices";

    export default {
          components: {
                Datepicker 
            },
        setup() {
        const date = ref(new Date());
         const highlightedDates = ref([
          addDays(new Date(), 1),
          addDays(new Date(), 2),
          addDays(new Date(), 3),
        ])
        
        return {
            date,
            highlightedDates
            
        }
        },
        props:{
            user:{
                type:Number
            }

        },
        data() {
			return {
                selected:null,
                Assess:{
                    customer:null,
                    slot:null,
                    date:null,
                    ELE:null,
                    OPE:null,
                    location:null,
                    discount_rate:null
                },
                highlighted:[],
                customerName:null,
                timeToSend:null,
                search:'',
                sendELE:null,
                sendOPE:null,
                sendlocation:null,
                freeSlot:[],
                optionsList:[]
			}
		},

        methods: {
        ...mapActions({
			getcustomers: 'Users/getAllCustomers',
            getstaff: 'Users/getStaff',
            getcustomer: 'Users/getCustomer',
            searchcustomers: 'Users/searchCustomers',
            getaddresses: 'Settings/getAllAddresses',
            getdiscounts: 'Settings/getAllDiscounts',
            getorganizations: 'Settings/getAllOrganizations',
            getslots: 'Slots/getSlotsForDate',
            getallslots: 'Slots/getAllSlots',
            clearslots: 'Slots/clearSlots',
        }),
        
        debCheck: _.debounce(function(){
            this.customerName = this.search
        if(this.search === ""|| this.search === null)
        {
            this.searchcustomers({input:null, number:15})
        }
        else{
            
            this.searchcustomers({input:this.search, number:100})
        }
        }, 700),
        newSlots(data){
            if(data){
                this.Assess.date=data
                let dat= this.Assess.date.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                    if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    }}
                )
            }  
        },
        slotSelect(){
            let thisSlot = this.slots.find(cus=> cus.id === this.Assess.slot)
            if(thisSlot.ope){
                this.Assess.OPE=thisSlot.ope
            }
            else{
                this.Assess.OPE=null
            }
            if(thisSlot.ele){
                this.Assess.ELE=thisSlot.ele
            }
            else{
                this.Assess.ELE=null
            }
            if(thisSlot.location){
                this.Assess.location=thisSlot.location
            }
            else{
                this.Assess.location=null
            }
        },
        timeSend(){
            let timeNow= ""
            if(this.timeToSend.hours < 10){
                timeNow= "0"+this.timeToSend.hours +":"+this.timeToSend.minutes
            }
            else{
                timeNow= this.timeToSend.hours +":"+this.timeToSend.minutes
            }
            let checkEmpty = this.slots.filter(cus => cus.start_time+cus.date === timeNow+":00"+this.nameDisplay )
            if (checkEmpty.length === 0){
                let newSlot= {
                "date":this.Assess.date.toISOString().substr(0,10),
                "start_time":timeNow,
                "ele":this.sendELE,
                "ope":this.sendOPE,
                "location":this.sendlocation
                }
                SlotsServices.postSlot(newSlot).then(()=>{
                    Swal.fire({
                    icon:"success",
                    title:"Success!",
                    text:"Added slot to " + timeNow + " on " + this.Assess.date,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                this.getallslots()
                this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                this.$forceUpdate
                document.getElementById("close").click()
                })
                .catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                this.getallslots()
                this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                this.$forceUpdate
                })

            }
            else{
                alert("Slot exists")
            }
            this.timeToSend=null
            },
            suggestcustomer(id,name, customer){
                this.selected= customer
                this.customerName = name
                this.Assess.customer = id
                this.customerOptions()
            },
            reSearch(){
                this.selected=null
                this.search = null
                this.customerName = null
                this.Assess.customer = null
                this.customerOptions()
            },
            timeEnd(){
            this.timeToSend=null
            },
            book(){
                if(this.user){
                    this.Assess.customer = this.user
                }
                if(this.Assess.customer && this.Assess.ELE && this.Assess.OPE && this.Assess.date && this.Assess.slot)
                {
                    let sendingObj = {
                        customer:this.Assess.customer,
                        ele:this.Assess.ELE,
                        ope:this.Assess.OPE,
                        location:this.Assess.location,
                        discount_rate:this.Assess.discount_rate
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())

                    SlotsServices.createAssessment(this.Assess.slot, getFormData(sendingObj)).then(()=>{
                    Swal.fire({
                    icon:"success",
                    title:"Success!",
                    text:"Booking successful.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                    router.push({name: 'AssessmentsTab', params: {currTab: "booked" }})
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                }
                else{
                    Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all fields before booking.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                            
                        }
                },
           
            bookRef(){
                if(this.user){
                    this.Assess.customer = this.user
                }
                if(this.Assess.customer && this.Assess.ELE && this.Assess.OPE && this.Assess.date && this.Assess.slot)
                {
                    let sendingObj = {
                        customer:this.Assess.customer,
                        ele:this.Assess.ELE,
                        ope:this.Assess.OPE,
                        location:this.Assess.location,
                        discount_rate:this.Assess.discount_rate
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    SlotsServices.createAssessment(this.Assess.slot, getFormData(sendingObj)).then(()=>{
                    Swal.fire({
                    icon:"success",
                    title:"Success!",
                    text:"Booking successful.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                    this.$router.go()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all fields before booking.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000})
                            
                }

		},
        customerOptions(){
        let ourCust= this.customerOnly.find(cus => cus.id === this.Assess.customer)
        let orgs = null
        let disc = null
            if(ourCust && ourCust.customerprofile){
                orgs = [ourCust.customerprofile.customer_company]
                orgs = orgs.concat(ourCust.customerprofile.other_affiliations)
            }
             
        if(orgs)
        {
            disc = []
            orgs.forEach(org => {
                disc = disc.concat(this.discounts.filter(d => d.organization === org)) 
            })     
            
        }
        
        
        if(disc)
        {
            this.optionsList = disc
        }
        else{
            this.Assess.discount_rate=null
            this.optionsList = []
        }
      }
        
         },

        computed: {
        ...mapState({
            customers: state => state.Users.customers,
            customer: state=> state.Users.customer,
            addresses: state => state.Settings.addresses,
            discounts: state => state.Settings.discounts,
            organizations: state => state.Settings.organizations,
            slots: state => state.Slots.slotD,
            allSlots: state => state.Slots.slots,
        }),
        ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ])
      ,
      customerRange(){
        if(this.customerOnly)
        {return this.customerOnly.slice(0,7)}
        else{
            return []
        }
      }
      
		},

        created() {
			this.emptyState = 'Sorry!, we couldnt find matching results'

            this.getaddresses()
            this.getdiscounts()
            this.getorganizations()
            this.getstaff()
            this.getcustomer(this.user)
            this.clearslots()
                if(this.Assess.date){
                let dat= this.Assess.date.toISOString()
                this.getslots(dat.substr(0,10))
                }
                else{
                    this.slots=null
                }
                if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    
                }
                this.getallslots()
            
                        watchEffect(()=>{
                            if(this.user){
                this.Assess.customer=this.user
                SettingsServices.getDiscountsUser(this.user).then((r)=> {this.optionsList = r.data})
                
                
            }
            if(this.allSlots){
                this.highlighted=[]
                    this.allSlots.filter(slot => slot.assessment === null).forEach((s)=>{
                        this.highlighted.push(new Date(s.date))
                    })
                    }
            
            
            })

		},
    }
</script>

<style scoped>
#search:focus+#list{
    display: flex;
}
#search:not(focus)+.lister{
    border:none;
}
.searcher:focus{
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
#list:hover{
    display: flex;
}
</style>